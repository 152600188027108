<template>
  <lazy-widgets-newsletter-popup :_email="email" v-if="showModal"></lazy-widgets-newsletter-popup>
  <div>
    <footer class="footer-light printPdf">
      <div class="light-layout">
        <div class="container">
          <section class="small-section border-section border-top-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="subscribe">
                  <div>
                    <h4>{{ $t("know_it") }}</h4>
                    <p>
                      {{ $t("never_miss") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <form class="form-inline subscribe-form auth-form needs-validation" id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form">
                  <div class="form-group mx-sm-3">
                    <input type="text" v-model="email" class="form-control" name="EMAIL" id="mce-EMAIL"
                      :placeholder="$t('enter_email')" required="required" />
                  </div>
                  <button @click.prevent="showNewsLetter" type="submit" class="btn btn-solid" id="mc-submit">
                    {{ $t('subscribe') }}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="section-b-space light-layout">
        <div class="container">
          <div class="row footer-theme partition-f">
            <div class="col-lg-4 col-md-6">
             
              <div class="footer-contant">
                <div class="footer-logo">
                  <img height="120px" src="~~/assets/cld-logo.svg" alt="logo" />
                </div>
                <p v-html="$t('cld_desc1', { newline: '<br>' })">
                </p>
                <div class="footer-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/clddistribution" target="_blank" aria-label="Facebook" >
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@clddistribution8447" target="_blank" aria-label="Youtube" >
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/clddistribution/" target="_blank" aria-label="Instagram" >
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/1644026/" target="_blank" aria-label="LinkedIn" >
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col  offset-xl-1">
              <div class="sub-title" v-if="isAuthenticated">
                <div class="footer-title">
                  <h4>{{ $t("account_links") }} </h4>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li>
                      <NuxtLinkLocale to="/order/history" class="nav-link">
                        {{ $t(`menu.order_history`) }}
                      </NuxtLinkLocale>
                    </li>

                    <li>
                      <NuxtLinkLocale to="/order/cartmanagement" class="nav-link">
                        {{ $t(`menu.cart_management`) }}
                      </NuxtLinkLocale>
                    </li>

                    <li>
                      <NuxtLinkLocale to="/order/backorders" class="nav-link">
                        {{ $t(`menu.back_orders`) }}
                      </NuxtLinkLocale>
                    </li>
                    <li>
                      <NuxtLinkLocale to="/order/deliverables" class="nav-link">
                        {{ $t(`menu.deliverable_orders`) }}
                      </NuxtLinkLocale>
                    </li>
                    <li>
                      <NuxtLinkLocale to="/order/unavailableDeliveries" class="nav-link">
                        {{ $t(`menu.awaiting_delivery`) }}
                      </NuxtLinkLocale>
                    </li>
                    <li>
                      <a target="_blank" :href="tAndCPdf[selectedLanguage]" class="nav-link">
                        {{ $t(`general_terms_and_conditions`, "General terms and conditions") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="sub-title">
                <div class="footer-title">
                  <h4>{{ $t("store_information") }}</h4>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li>
                      <i class="fa fa-map-marker"></i>CLD warehouse, Rue du
                      Grand Champs 14, 5380 Fernelmont, Belgium
                    </li>
                    <li>
                      <i class="fa fa-phone"></i>{{ $t("call_us") }}: +32 (0)
                      818-302-02
                    </li>
                    <li>
                      <i class="fa fa-envelope-o"></i>{{ $t("email_us") }}:
                      <a href="#">infos@cld.be</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="sub-footer">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-md-6 col-sm-12">
              <div class="footer-end">
                <p>
                  <i class="fa fa-copyright" aria-hidden="true"></i> 2023-24 CLD
                  Distribution
                </p>
              </div>
            </div>
            <!-- <div class="col-xl-6 col-md-6 col-sm-12">
            </div> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { useFilterStore } from '~/store/FilterStore';
import { useUserStore } from '~/store/UserStore';
import { useUtilityStore } from '~/store/UtililityStore';

const email = ref('')
const filterStore = useFilterStore()
const showModal = computed(() => filterStore.showNewsLetterModal)
const showNewsLetter = () => {
  filterStore.setShowNewsLetterModal(true)
}
const userStore = useUserStore();
const isAuthenticated = computed(() => userStore.isAuthenticated);
const utilityStore = useUtilityStore()
const selectedLanguage = computed(() => utilityStore.selectedLanguage)
const tAndCPdf = {
  "de_DE": '/pdf/terms/General_conditions_of_sales_for_CLD_distribution_SA-EN.pdf',
  "es_ES": '/pdf/terms/General_conditions_of_sales_for_CLD_distribution_SA-EN.pdf',
  "sv_SE": '/pdf/terms/General_conditions_of_sales_for_CLD_distribution_SA-EN.pdf',
  'en_GB': '/pdf/terms/General_conditions_of_sales_for_CLD_distribution_SA-EN.pdf',
  "nl_BE": '/pdf/terms/Algemene_verkoopvoorwaarden_van_CLD_distribution_SA-NL.pdf',
  "fr_BE": "/pdf/terms/Conditions_générales_de_vente_CLD_distribution_SA-FR.pdf"
}

</script>
